import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import useIsMobile from "../hooks/useIsMobile";

const Arrow = (props) => {
  const { onClick } = props;
  const arrowColor =
    props.color === "smoke"
      ? "bg-smoke group-hover:bg-bronze"
      : "bg-ink group-hover:bg-bronze";
  const arrowType =
    props.type === "next"
      ? [
          "left-[90px]",
          "-right-[9px] -rotate-45",
          "-right-[9px] rotate-45",
          "top-0 -right-[6px]",
        ]
      : [
          "left-[45px]",
          "-left-[2px] rotate-45",
          "-left-[2px] -rotate-45",
          "top-0 left-0",
        ];
  return (
    <button
      onClick={onClick}
      className={`absolute -top-7 group h-[25px] w-[25px] cursor-pointer ${arrowType[0]}`}
      aria-label={`Click to navigate through crew member profiles`}
    >
      <span
        className={`w-3 h-[1px] block absolute top-1 transition-all duration-400 ${arrowType[1]} ${arrowColor}`}
      ></span>
      <span
        className={`w-3 h-[1px] block absolute -top-1 transition-all duration-400 ${arrowType[2]} ${arrowColor}`}
      ></span>
      <span
        className={`w-8 h-[1px] block absolute transition-all duration-400 ${arrowType[3]} ${arrowColor}`}
      ></span>
    </button>
  );
};

export default function CrewSlider({ data }) {
  const isMobile = useIsMobile(576);
  const [modalActive, setModalActive] = useState(false);
  const [crewData, setCrewData] = useState(
    data.crew_members.map((member) => ({
      ...member,
      showModal: false,
    }))
  );
  const [slidesShown, setSlidesShown] = useState(data.slides_to_show);
  
  const calculateYearsOfService = (memberStartDate) => {
    if(memberStartDate){
      const totalMillisecondsServed = Date.now() - new Date(memberStartDate); 
      const totalYearsServed = Math.floor((totalMillisecondsServed / (1000 * 60 * 60 * 24)) / 365);
      if(totalYearsServed > 9){
        return (
          <StaticImage
          src="../images/the-crew/5-years.png"
          className="w-16 transition-all duration-300 hover:scale-125 hover:-rotate-[30deg]"
          alt="Legendary status badge"
        />
        )
      }else{
        return (
          Array.from({ length: totalYearsServed }).map((_, index) => (
            <StaticImage
              key={index}
              src="../images/the-crew/1-year.svg"
              className="w-10 transition-all duration-300 hover:scale-125 hover:rotate-[30deg]"
              alt="One year badge"
            />
          ))
        )
      }
    }
  }

  const handleShowModal = (memberId) => {
    const updatedCrewData = crewData.map((member) => {
      if (member.name === memberId) {
        return { ...member, showModal: true };
      }
      return { ...member, showModal: false };
    });
    setCrewData(updatedCrewData);
    setModalActive((prev) => !prev);
  };

  const closeAllModals = () => {
    setCrewData(
      crewData.map((member) => {
        return { ...member, showModal: false };
      })
    );
    setModalActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setSlidesShown(1);
      } else if (window.innerWidth < 800) {
        setSlidesShown(2);
      } else if (window.innerWidth < 1080) {
        setSlidesShown(3);
      } else {
        setSlidesShown(data.slides_to_show);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data.slides_to_show]);

  const settings = {
    draggable: data.draggable,
    dots: false,
    infinite: true,
    className: data.slider_classes,
    speed: 500,
    slidesToShow: slidesShown,
    slidesToScroll: 1,
    responsive: data.response,
    arrows: data.arrow_type === "none" ? false : true,
    nextArrow: <Arrow color={data.arrow_type} type={"next"} />,
    prevArrow: <Arrow color={data.arrow_type} type={"previous"} />,
  };
  return (
    <div className={`${data.container_classes}`}>
      {crewData.length === 1 ? (
        <div
          key={crewData[0].name}
          className={`group cursor-pointer px-8 md:px-0 ${data.single_container_classes}`}
          onClick={() => handleShowModal(crewData[0].name)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleShowModal(crewData[0].name);
            }
          }}
          role="button"
        >
          <div
            className={`h-[400px] sm:h-[300px] sm:w-[300px] bg-cover bg-center bg-no-repeat`}
            style={{
              backgroundImage: `url(${crewData[0].smallProfile.sourceUrl})`,
            }}
          ></div>
          <p
            className={`gin-regular text-smoke text-[28px] uppercase mt-4 ${
              crewData[0].name.length >= 20 ? "leading-tight" : ""
            }`}
          >
            {crewData[0].name}
          </p>
          <p className="elza text-bronze text-sm font-light leading-normal tracking-tight">
            {crewData[0].jobTitle}
          </p>
        </div>
      ) : isMobile ? (
        <div className={`flex flex-col gap-y-14 ${data.slider_classes}`}>
          {crewData.map((member) => {
            return (
              <div
                key={member.name}
                className="group cursor-pointer px-4"
                onClick={() => handleShowModal(member.name)}
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleShowModal(member.name);
                    e.preventDefault();
                  }
                }}
              >
                <div
                  className="h-[400px] sm:h-[300px] bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${member.smallProfile.sourceUrl})`,
                  }}
                ></div>
                <p
                  className={`gin-regular text-smoke text-[28px] uppercase mt-4 ${
                    member.name.length >= 20 ? "leading-tight" : ""
                  }`}
                >
                  {member.name}
                </p>
                <p className="elza text-bronze text-sm font-light leading-normal tracking-tight">
                  {member.jobTitle}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <Slider {...settings}>
          {crewData.map((member) => {
            return (
              <div
                key={member.name}
                className={`group cursor-pointer px-3`}
                onClick={() => handleShowModal(member.name)}
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleShowModal(member.name);
                    e.preventDefault();
                  }
                }}
              >
                <div
                  className={`h-[400px] sm:h-[300px] bg-cover bg-center bg-no-repeat`}
                  style={{
                    backgroundImage: `url(${member.smallProfile.sourceUrl})`,
                  }}
                ></div>
                <p
                  className={`gin-regular text-smoke text-[28px] uppercase mt-4 ${
                    member.name.length >= 20 ? "leading-tight" : ""
                  }`}
                >
                  {member.name}
                </p>
                <p className="elza text-bronze text-sm font-light leading-normal tracking-tight">
                  {member.jobTitle}
                </p>
              </div>
            );
          })}
        </Slider>
      )}
      {crewData.map((member) => {
        return (
          <div
            key={member.name}
            className={`bg-iron flex flex-col items-center justify-center fixed inset-0 m-5 md:m-auto md:h-[700px] md:w-[900px] lg:w-[1000px] z-50 transition-all duration-500 overflow-scroll ${
              member.showModal
                ? "translate-y-[40px] md:translate-y-0 opacity-100"
                : "-translate-y-[1000px] opacity-0"
            }`}
          >
            <div className="relative grid md:grid-cols-6 h-full w-full">
              <button
                className="absolute top-[30px] right-[30px] group h-[35px] w-[35px]"
                onClick={closeAllModals}
                aria-label="Click to close all open modal windows"
              >
                <span className="bg-bronze w-10 h-[1px] rotate-45 absolute top-4 -right-[2px] transition-all duration-300 group-hover:rotate-90"></span>
                <span className="bg-bronze w-10 h-[1px] -rotate-45 absolute top-4 -right-[2px] transition-all duration-300 group-hover:rotate-0"></span>
              </button>
              <div
                className="hidden md:block md:col-span-2 bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${member.largeProfile.sourceUrl})`,
                }}
              ></div>
              <div className="md:col-span-4 px-10 py-12 md:p-16 overflow-scroll">
                <h4 className="text-smoke text-6xl gin-regular uppercase">
                  {member.name}
                </h4>
                <p className="text-bronze text-[28px] gin-regular uppercase mb-5">
                  {member.jobTitle}
                </p>
                <p className="text-smoke elza font-light text-lg tracking-body leading-[27px]">
                  {member.biography}
                </p>
                <ul className="mt-10 flex flex-col gap-y-5">
                  {member.questions.map((item) => {
                    return (
                      <li key={item.question} className="border border-smoke">
                        <p className="text-smoke gin-regular text-[28px] uppercase px-5 py-3 border-b border-smoke leading-tight">
                          {item.question}
                        </p>
                        <p className="text-smoke elza font-light text-lg tracking-body leading-[27px] px-5 py-4">
                          {item.response}
                        </p>
                      </li>
                    );
                  })}
                </ul>
                <div className="flex gap-x-3 justify-start mt-10">                  
                  {calculateYearsOfService(member.startingDate)}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div
        onClick={closeAllModals}
        className={`fixed bg-ink transition-all duration-500 top-0 left-0 h-full w-full z-40 ${
          modalActive ? "visible opacity-90" : "invisible opacity-0"
        }`}
        tabIndex={0}
        role="button"
        aria-hidden={!modalActive}
        aria-label="Click to close all open modal windows"
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            closeAllModals();
            e.preventDefault();
          }
        }}
      ></div>
    </div>
  );
}
